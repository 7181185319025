import React, { useEffect } from "react";
import { Link as RouterLink, graphql, PageProps } from "gatsby";
import { Box, Link, Typography } from "@mui/material";
import Layout from "../components/Layout";
import { DateTime } from "luxon";
import "../styles/style.css";
import OtherBlogPost from "../components/OtherBlogPost";
import { StaticImage } from "gatsby-plugin-image";
import { MetaData } from "../components/common/meta";
import { Helmet } from "react-helmet";
import Zoom from "react-medium-image-zoom";
import { createRoot } from "react-dom/client";

import "../styles/zoom.css";
import "katex/dist/katex.min.css";
import renderMathInElement from "katex/dist/contrib/auto-render";

import bricks from "../images/bricks.svg";
import Contribuitor from "../components/Contribuitor";

import Share from "../components/Share";
import Subscribe2 from "../components/Subscribe2";
import Disclaimer from "../components/Disclaimer";

import "../styles/prismTheme.css";

const postStyle = {
  maxWidth: "860px",
  mx: "auto",
  pt: 15,
  pb: 8,
};

const tagLinkStyle = {
  textDecoration: "none",
  textTransform: "capitalize",
  display: "inline-block",
  padding: 0.5,
  fontSize: 12,
  color: "#5A79BA",
  transition: "250ms cubic-bezier(0.4, 0, 0.2, 1)",
  "&:not(:first-of-type):before": {
    content: "'/'",
    color: "#5A79BA !important",
    pr: 1,
  },
  "&:first-of-type": {
    pl: 0,
  },
  "&:hover": {
    color: "#fff",
  },
  "&::selection": {
    color: "#fff",
    background: "#79859d",
  },
};

export interface Author {
  name: string;
  profile_image?: string;
  slug?: string;
}
export interface Tag {
  slug: string;
  name: string;
}
export interface PostInterface {
  title: string;
  slug: string;
  feature_image: string;
  published_at: string;
  html: string;
  reading_time: string;
  excerpt: string;
  authors: Author[];
  tags: Tag[];
}

export interface ghostPostInterface {
  ghostPost: PostInterface;
}

const Post = ({
  data,
  location,
}: {
  data: ghostPostInterface;
  location: PageProps;
}) => {
  const post = data.ghostPost;
  //@ts-ignore
  const OtherPosts = data.allGhostPost.edges;
  const url = `/writing/${post.slug}/`;

  //replace Ghost ref

  const content = post.html.replace(
    /ref=jumpcrypto-com.ghost.io/gi,
    "ref=jumpcrypto.com"
  );

  const date = DateTime.fromJSDate(new Date(post.published_at))
    .toFormat("MMM dd y")
    .replace("!", "'");

  useEffect(() => {
    let tables = document.querySelectorAll("table");
    tables.forEach((el) => {
      // create wrapper container
      var wrapper = document.createElement("div");
      wrapper.className = "resposive-table";
      // insert wrapper before el in the DOM tree
      el.parentNode?.insertBefore(wrapper, el);
      // move el into wrapper
      wrapper.appendChild(el);
    });

    // kind of a hack, let the page render then render Katex in the body
    setTimeout(() => {
      renderMathInElement(document.body, { throwOnError: false });
    }, 20);
  });

  useEffect(() => {
    // 1. extract imgs and src
    const containerImgs = document.getElementById("postStyle");
    //@ts-ignore
    const imgs = containerImgs.getElementsByTagName("img");
    const srcArray = Array.from(imgs).map((img) => img.src);

    // 2. wrap images with div then replace each div using ReactDOM.render
    for (let i = 0; i < imgs.length; i++) {
      const wrapper = document.createElement("div");
      wrapper.setAttribute("id", `img-${i}`);

      imgs[i]?.parentNode?.insertBefore(wrapper, imgs[i]);
      wrapper.appendChild(imgs[i]);
      const root = createRoot(wrapper);

      root.render(
        <Zoom>
          <img src={srcArray[i]} />
        </Zoom>
      );
    }
  }, []);

  return (
    <Layout>
      <MetaData data={data} location={location} type="article" />
      <Helmet>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/prism/1.28.0/components/prism-core.min.js"></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/prism/1.28.0/plugins/autoloader/prism-autoloader.min.js"></script>
      </Helmet>
      <Box
        component="img"
        src={bricks}
        sx={{
          position: "absolute",
          left: 0,
          top: "0",
          zIndex: -1,
          pointerEvents: "none",
        }}
      />
      <Box component="div" sx={postStyle}>
        <Box
          component="div"
          sx={{
            maxWidth: 860,
            mx: "auto",
            mt: 4,
            mb: { xs: 4, md: 8 },
            textAlign: "center",
          }}
        >
          <Typography variant="h2" component="h1" sx={{ lineHeight: 1.2 }}>
            {post.title}
          </Typography>
        </Box>
        <Box
          component="div"
          sx={{ mb: 4, display: "flex", flexWrap: "wrap", maxWidth: 600 }}
        >
          {post.authors.map((c, idx) => (
            <Box
              component="span"
              key={idx}
              sx={{
                display: "flex",
                alignItems: "center",
                mb: 0.8,
                color: "#1CE7C2",
                mr: 2.2,
              }}
            >
              <Box
                component="div"
                className="img"
                sx={{
                  width: 25,
                  height: 25,
                  borderRadius: "50%",
                  overflow: "hidden",
                  mr: 1.4,
                  flex: "0 0 auto",
                  backgroundColor: "#1CE7C2",
                  border: "2px solid #0D1F46",
                  transition: ".3s ease-in-out",
                }}
              >
                {c.profile_image && (
                  <Box
                    component="img"
                    src={c.profile_image}
                    alt={c.name}
                    sx={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                )}
              </Box>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: 300,
                  fontSize: 14,
                  transition: ".3s ease-in-out",
                }}
              >
                {c.name}
              </Typography>
            </Box>
          ))}
        </Box>
        <Box
          component="div"
          sx={{
            display: { xs: "block", sm: "flex" },
            justifyContent: "space-between",
            mb: 4,
          }}
        >
          <Box component="div">
            {post.tags.map((item) => (
              <Link
                key={item.slug}
                sx={tagLinkStyle}
                component={RouterLink}
                to={`/writing/${item.slug}`}
              >
                {item.name}
              </Link>
            ))}
          </Box>
          <Box
            component="div"
            sx={{
              textAlign: { sm: "right" },
              mt: { xs: 1, sm: 0 },
            }}
          >
            <Typography
              sx={{
                fontSize: 12,
                color: "#5A79BA",
                "&::selection": {
                  color: "#fff",
                  background: "#79859d",
                },
              }}
            >
              {date} _ {post.reading_time} min read
            </Typography>
          </Box>
        </Box>
        {post.feature_image ? (
          <Box
            component="div"
            sx={{
              display: "block",
              height: 156,
              position: "relative",
              border: "1px solid #4B608D",
              mb: 4,
            }}
          >
            <Box
              component="img"
              src={post.feature_image}
              alt={post.title}
              sx={{
                position: "absolute",
                left: 0,
                top: 0,
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            />
          </Box>
        ) : null}
        <Box
          className="postStyle"
          id="postStyle"
          component="div"
          sx={{
            backgroundColor: "#091A3D",
            border: "1px solid #4B608D",
            px: { xs: 2, md: 5 },
            py: { xs: 2, md: 6 },
            position: "relative",
          }}
        >
          <section dangerouslySetInnerHTML={{ __html: content }} />
          <Box component="div" sx={{ mt: 7 }}>
            <Share title={post.title} url={`https://jumpcrypto.com${url}`} />
          </Box>
        </Box>
      </Box>

      <Subscribe2 />
      <Box
        component="div"
        sx={{
          px: { xs: 0, md: 2.5, xl: 5 },
          mt: 4,
          mb: { xs: 10, md: 20 },
          maxWidth: 1100,
          mx: "auto",
        }}
      >
        <Typography
          variant="h4"
          sx={{
            fontSize: "24px",
            lineHeight: "22px",
            mb: 3.5,
          }}
        >
          More articles
        </Typography>

        <Box
          component="div"
          sx={{
            display: "grid",
            gridTemplateColumns: { md: "repeat(3, 1fr)" },
            gap: "10px",
          }}
        >
          {OtherPosts.map((post: any, index: number) => (
            <OtherBlogPost key={index} post={post.node} />
          ))}
        </Box>
      </Box>
      <Disclaimer />
      <StaticImage
        src="../images/brandLines.svg"
        alt=""
        placeholder="blurred"
        style={{
          position: "absolute",
          right: "0",
          bottom: "0",
          zIndex: -1,
          pointerEvents: "none",
        }}
      />
    </Layout>
  );
};

export default Post;

export const postQuery = graphql`
  query ($slug: String!, $limit: Int!, $skip: Int!) {
    ghostPost(slug: { eq: $slug }) {
      ...GhostPostFields
    }

    allGhostPost(
      filter: { slug: { ne: $slug } }
      limit: $limit
      skip: $skip
      sort: { fields: [featured, published_at], order: [DESC, DESC] }
    ) {
      edges {
        node {
          slug
          title
          feature_image
          published_at
          reading_time
          excerpt
          tags {
            slug
            name
          }
        }
      }
    }
  }
`;
