import React from "react";
import { Link as RouterLink } from "gatsby";
import { Box, Typography, Link } from "@mui/material";
import { DateTime } from "luxon";
import { PostInterface } from "../templates/post";
import Share from "./Share";

function addElipsis(str: string) {
  const num = 200; //desired lenght
  if (str.length > num) {
    return str.slice(0, num) + "...";
  } else {
    return str;
  }
}

const BlogPost = ({ post }: { post: PostInterface }) => {
  const url = `/writing/${post.slug}/`;
  const date = DateTime.fromJSDate(new Date(post.published_at))
    .toFormat("MMM dd y")
    .replace("!", "'");

  const tags = post.tags
    .map(function (item) {
      return item["name"];
    })
    .join(", ");

  let excerpt = post.excerpt;

  //  remove urls in brackets, ie [https://example.com/some-route?foo=bar]
  excerpt = excerpt.replace(/[\n\r\s]+\[(?:https?|ftp):\/\/[\n\S]+]/g, "");
  //  remove partial urls in brackets, ie [https://www.ex
  excerpt = excerpt.replace(/[\n\r\s]+\[(?:https?|ftp):\/\/[\n\S]+/g, "");
  //  remove  *
  excerpt = excerpt.replace(/(?:__|[*#])|\[(.*?)\]\(.*?\)/gm, "");

  excerpt = addElipsis(excerpt);

  return (
    <Box
      component="div"
      sx={{
        position: "relative",
        backgroundColor: "#0C1F47",
        border: "1px solid #4B608D",
        mb: 1.1,
        transition: ".3s ease-in-out",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        pb: 3,
        flex: 1,
        "&:hover": {
          backgroundColor: "#122653",
        },
      }}
    >
      <Link component={RouterLink} to={url} sx={{}}>
        <Box
          component="span"
          sx={{
            display: "block",
            height: 80,
            position: "relative",
          }}
        >
          {post.feature_image && (
            <Box
              component="img"
              src={post.feature_image}
              alt={post.title}
              sx={{
                position: "absolute",
                left: 0,
                top: 0,
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            />
          )}
        </Box>

        <Box
          component="span"
          sx={{
            display: "block",
            p: 2,
          }}
        >
          <Typography
            variant="h5"
            sx={{ mb: 1.6, transition: ".3s ease-in-out" }}
          >
            {post.title}
          </Typography>
          <Typography sx={{ mb: 1.6 }}>{excerpt}</Typography>
        </Box>
      </Link>
      <Box
        component="span"
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          justifyContent: { xs: "flex-start", sm: "space-between" },
          alignItems: { sm: "center" },
          px: 2,
        }}
      >
        <Typography
          sx={{ fontSize: 12, color: "#5A79BA", mb: { xs: 1, sm: 0 } }}
        >
          {date} _ {post.reading_time} min
        </Typography>
        <Share title={post.title} url={`https://jumpcrypto.com${url}`} />
      </Box>
    </Box>
  );
};

export default BlogPost;
