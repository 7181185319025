import React from "react";
import { Box, Typography, TextField, Button } from "@mui/material";
import Arrow from "../images/arrow2.inline.svg";
import bg from "../images/subscribe2.svg";
import SubscribeForm from "./SubscribeForm";

const Subscribe2 = () => {
  return (
    <Box
      component="div"
      sx={{
        backgroundColor: "#1CE7C2",
        border: "1px solid #4B608D",
        py: 3,
        pl: { xs: 2, md: 4 },
        pr: { xs: 2, md: 5 },
        mb: { xs: 5, md: 8 },
        position: "relative",
        overflow: "hidden",
        maxWidth: 860,
        display: { md: "flex" },
        alignItems: "center",
        justifyContent: "space-between",
        mx: "auto",
      }}
    >
      <Box
        component="img"
        src={bg}
        sx={{
          position: "absolute",
          right: -50,
          top: 0,
          height: "100%",
          width: "100%",
          objectFit: { xs: "cover", md: "contain" },
          objectPosition: "right center",
          opacity: { xs: 0.3, md: 1 },
        }}
      />
      <Typography
        variant="h4"
        sx={{
          fontWeight: 600,
          lineHeight: 1.2,
          letterSpacing: "-0.01em",
          color: "#0C1F47",
          maxWidth: { md: 320 },
          flex: "0 0 auto",
        }}
      >
        Stay up to date with the latest from Jump_
      </Typography>
      <SubscribeForm />
    </Box>
  );
};

export default Subscribe2;
